<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        2024年度河北省医学影像质量管理与控制中心工作会议圆满落幕
      </div>
      <section>
        <div class="div_img">
          <img src="@/assets/HY20241208/1.png" alt="">
        </div>
        <div class="div_p">
          2024年12月8日，河北省医学影像质量管理与控制中心2024年度工作会议在河北医科大学第一医院精致报告厅隆重召开。本次会议汇聚了国内医学影像质控领域的顶尖专家和学者，共同探讨医学影像质控与诊断的前沿技术与实践经验，推动了京津冀地区医学影像领域的协同发展。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241208/2.png" alt="">
        </div>
        <div class="div_p">
          河北医科大学第一医院党委副书记、院长乔治斌教授任大会主席并致开幕辞。他指出，河北医科大学第一医院始终秉承“精致医院”理念，追求卓越的医疗质量和服务水平，其中医疗影像平台建设是维系医疗质量的基础，医学影像质量控制是也保障是医疗安全和诊疗质量的核心环节，尤其是在精准医疗时代愈发重要。河北医科大学第一医院多年来持续重点关注医学影像质量的规范化管理，不仅致力于推动省内质控体系的标准化建设，还积极响应京津冀协同发展战略，助力区域影像检查结果互认的落地实施。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241208/3.png" alt="">
        </div>
        <div class="div_p">
          北京市医学影像质量控制和改进中心主任、中国工程院王振常院士受邀线上致辞。他提到京津冀地区的医学影像检查结果互认是区域医疗一体化的重要成果，重点强调了影像云技术在现代医疗体系中的关键作用，以及它在推动京津冀区域协同发展和医疗质量提升方面的重要意义。他呼吁通过标准化和规范化的质控管理，提升诊疗效率和患者体验，真正惠及京津冀三地的人民群众。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241208/4.png" alt="">
        </div>
        <div class="div_p">
          天津市医学影像质控中心主任刘筠教授出席会议并致辞，她回顾了区域协同发展的重要历程，总结了影像检查互认工作八年来的显著成果。刘筠教授对河北省医学影像质量管理与控制中心在互认工作中的努力表示高度肯定，并期望通过此次会议进一步凝聚共识、交流经验，为京津冀协同发展注入更多动力。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241208/5.png" alt="">
        </div>
        <div class="div_p">
          河北省医学影像质控中心主任刘怀军教授出席会议并致辞，他指出，京津地区作为国内医疗质量管理的标杆，为河北省的医学影像质控工作提供了宝贵的借鉴。未来将继续融入京津冀一体化发展格局，进一步优化省内影像检查流程，推动县域医疗机构的影像质量提升，将河北省的影像质控工作推向新高度。
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241208/6.png" alt="">
        </div>
        <div class="div_p">
          河北省医学影像质控中心秘书长、河北医科大学第一医院放射与核医学科主任王勇教授任大会执行主席并主持会议。
        </div>
        <div class="div_p div_h2">
          质控会议：聚焦影像质控新标准
        </div>
        <div class="div_p">
          质控会议作为本次大会的核心环节之一，全面围绕医学影像质控的最新实践和发展方向展开，涵盖了国家质控指标体系建设、区域质控经验分享以及基层医院质控管理探索等多方面内容。通过权威专家的深度讲解和案例分析，参会者不仅深入了解了当前国内医学影像质控领域的最新成果，还获得了面向未来的实践指导。这一环节充分体现了医学影像质控在保障医疗质量安全、提升诊疗精准性以及推动医疗资源高效利用中的重要作用，特别是在京津冀协同发展背景下，影像检查结果互认和数字影像云的推广成为亮点。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/7.png" alt="">
          <img src="@/assets/HY20241208/8.png" alt="">
        </div>
        <div class="div_p">
          薛华丹教授（国家放射影像质控中心副主任、北京协和医院）：分享了国家放射影像质控指标体系建设的最新进展，强调以数据为导向，推动质控工作的标准化和精细化。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/9.png" alt="">
          <img src="@/assets/HY20241208/10.png" alt="">
        </div>
        <div class="div_p">
          卢洁教授（首都医科大学宣武医院专职副书记）：阐述了功能磁共振成像的质量控制，为前沿影像技术的规范化应用提供了实践依据。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/11.png" alt="">
          <img src="@/assets/HY20241208/12.png" alt="">
        </div>
        <div class="div_p">
          刘筠教授（天津市第四中心医院院长）：探讨了在高质量发展要求下，天津市医学影像质控工作的创新实践，尤其是在数字化和智能化方面的突破。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/13.png" alt="">
          <img src="@/assets/HY20241208/14.png" alt="">
        </div>
        <div class="div_p">
          魏璇教授（首都医科大学附属北京友谊医院）：介绍了北京市医学影像检查结果互认工作的阶段性成果，以及影像云平台对区域医疗资源整合的关键作用。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/15.png" alt="">
          <img src="@/assets/HY20241208/16.png" alt="">
        </div>
        <div class="div_p">
          刘怀军教授（河北医科大学第二医院）：详细阐述了河北省在MR质控体系建设中的探索路径，为推动全省影像检查标准化提供了有力支撑。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/17.png" alt="">
          <img src="@/assets/HY20241208/18.png" alt="">
        </div>
        <div class="div_p">
          李晓敏教授（邢台市第一医院）：介绍了市级医院放射与核医学整合影像质控管理实践，总结了整合资源、提升质控效率的宝贵经验。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/19.png" alt="">
          <img src="@/assets/HY20241208/20.png" alt="">
        </div>
        <div class="div_p">
          王清涛教授（清河县人民医院）：分享了县域影像科整合后的一体化质控管理模式，为基层医疗影像质量提升提供了范例。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/21.png" alt="">
          <img src="@/assets/HY20241208/22.png" alt="">
        </div>
        <div class="div_p">
          王勇教授（河北医科大学第一医院）：回顾了2024年的工作成果，提出2025年的工作计划，特别是强化县域影像质控和数字化平台建设。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/23.png" alt="">
          <img src="@/assets/HY20241208/24.png" alt="">
        </div>
        <div class="div_p">
          綦维维教授（北京大学人民医院）：详细介绍了头颈CTA的规范化扫描，为精准影像诊断提供技术支持。
        </div>
        <div class="div_p div_h2">
          学术会议：聚焦前沿与创新
        </div>
        <div class="div_p">
          学术会议聚焦影像诊断领域规范化、精准化的相关热点问题与前沿技术，展示了肿瘤诊断、罕见病鉴别等多个方面的最新研究进展。参会专家从影像学科发展的不同角度，分享了精准诊疗的先进理念和实践经验，推动了学术理论与临床应用的有机结合，为与会人员提供了丰富的学习素材和创新思路。这一环节不仅体现了影像技术在诊疗过程中的独特价值，也为未来的研究和实践指明了方向。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/25.png" alt="">
          <img src="@/assets/HY20241208/26.png" alt="">
        </div>
        <div class="div_p">
          刘剑羽教授（北京大学第三医院）：影像学在宫颈癌诊断中的核心作用，强调影像在精准肿瘤治疗中的重要性。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/27.png" alt="">
          <img src="@/assets/HY20241208/28.png" alt="">
        </div>
        <div class="div_p">
          赵阳教授（天津医科大学第二医院副院长）：泌尿系统疾病影像诊断的最新进展，展示了影像技术在临床辅助中的创新应用。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/29.png" alt="">
          <img src="@/assets/HY20241208/30.png" alt="">
        </div>
        <div class="div_p">
          孟祥水教授（山东大学齐鲁医院青岛院区副院长）：线粒体脑肌病的影像诊断，从基础到临床为参会者提供了全面的视角。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/31.png" alt="">
          <img src="@/assets/HY20241208/32.png" alt="">
        </div>
        <div class="div_p">
          任庆云教授（河北医科大学第一医院）：IgG4相关性硬脑膜炎的影像诊断及鉴别诊断，深入探讨了此类疾病的影像学特征及临床意义。
        </div>
        <div class="div_p">
          此外，大会还特别邀请了多位省内外权威影像质控专家担任嘉宾主持，包括北京大学人民医院程谨教授、天津市人民医院李一鸣教授、天津市第四中心医院王浩教授、河北省人民医院陈英敏教授、河北医科大学第二医院杨海庆教授、河北医科大学第三医院赵建教授、河北医科大学第四医院杨光教授、河北省中医院张泽坤教授、河北工程大学附属医院李保卫教授、开滦总医院乔建民教授、河北省胸科医院纪俊雨教授、河北医科大学第一医院张志坤教授、河北医科大学第一医院张力教授。他们的点评为讨论带来了深入的学术见解。
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/33.png" alt="">
          <img src="@/assets/HY20241208/34.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/35.png" alt="">
          <img src="@/assets/HY20241208/36.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/37.png" alt="">
          <img src="@/assets/HY20241208/38.png" alt="">
        </div>
        <div class="vertical-img">
          <img src="@/assets/HY20241208/39.png" alt="">
          <img src="@/assets/HY20241208/40.png" alt="">
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241208/41.png" alt="">
        </div>
        <div class="div_p div_h2">
          总结与展望：以质控护航高质量医疗
        </div>
        <div class="div_img">
          <img src="@/assets/HY20241208/42.png" alt="">
        </div>
        <div class="div_p">
          闭幕式由王勇教授主持，他总结了此次会议的丰硕成果，并表示本次大会充分展现了省、市、县三级协作机制的重要性，为推动医学影像质控的规范化和高效化提供了宝贵经验。未来，河北省医学影像质控工作将继续加强区域协作，通过医学影像检查结果互认、影像云平台建设和质控标准化推广，进一步提高医疗质量安全，优化患者诊疗体验。
        </div>

        <div class="div_p div_footer">
          图文：李亚光
        </div>

      </section>
    </div>

  </div>
</template>

<script>
export default {
  name: "HY20241208",
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}
.div_h2 {
  font-weight: 600;
}
.div_img {
  text-align: center;
}
.div_tit {
  text-align: center;
}

.vertical-img img {
  width: 50% !important;
  display: inline-block;
}

.div_footer {
  text-align: right;
}
</style>